import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import '../styles/about.css'
// import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import team1 from '../images/team-1.jpg'
import team2 from '../images/team-2.jpg'
import team3 from '../images/team-3.jpg'
import team4 from '../images/team-4.jpg'
import team5 from '../images/team-5.jpg'
import team6 from '../images/team-6.jpg'
import team7 from '../images/team-7.jpg'
import team8 from '../images/team-8.jpeg'


const about = (probs) => {
    return (
        <Layout>
            <div className="about_background p_background_img">
                <div className="cover-img-overlay">
                    <Container>
                        <Row>
                            <Col xl={6} lg={6} md={6} >
                                {/* <div className="p_vertical_line tr"></div> */}
                                <h1 className="p_head_blog">About Ausflug</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* <div class="p_btm_svg"></div> */}
            <div className="all_section">
            <Container className="about_section">

                <Row className="row_1st">

                    <Col>
                        {/* <h2 className="head_about">About Ausflug</h2> */}
                        <p className="content_about">With our dedicated service and utmost customer satisfaction, Ausflug Holidays is the best for your travel needs by creating a strong presence in the tours and travel industry. The phoenix in medical tourism. We are a link that travels through the history of health tourism, combining mental, physical, and functional factors to nurture the human spirit. Our program is to expedite their services to you in collaboration with 40 leading Indian healthcare companies. Also, local guides for language convenience at your service in every state of your travel in India and abroad. We value you in all means and are committed to serving you in the best possible way.</p>
                    </Col>

                </Row>

                <Row className="mt-4">
                    <Col>
                        <h3 className="head_about">Vision and Mission</h3>
                        <p className="content_about">Health care is essential for human salvation. Our goal and vision are to be one of the leading travel and tourism service providers in India promoting eco-friendly tourism innovating ourselves to deliver the best in industry and to mold a healthy nation in order to achieve holistic humanity. We are on the verge of it.</p>
                        <h4 style={{ fontWeight: "bold" }}>our mission is to :</h4>

                        <ul className="ml-4">
                            <li>Set standards in the travel and tour.</li>
                            <li>Innovate in the design and development of travel packages.</li>
                            <li>To provide stimulant health care with more care in less time at a lower cost.</li>
                            <li>Focus on providing value for money services to guests.</li>
                        </ul>


                    </Col>
                </Row>

                {/* <Row className="mt-5"> */}
                    
                        {/* <div style={{display:"flex", justifyContent:"start"}}>
                            <div>
                            <h2 style={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center" }}>our team</h2>
                            <span style={{ backgroundColor: "#008FCC", width: "180px", height: "3px", margin: "auto ", display: "block" }}></span>
                            </div>
                        </div> */}
                        {/* <h4 className="mt-4" style={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center" }}>Executive Leadership</h4> */}
                    {/* <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons">
                            <div>
                                <img className="circle" src={team1} alt="" />
                                <h4 className="p1">Ziyad Ibrahim</h4>
                                <p className="aboutPerson">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Amet sed ac ac faucibus dolor donec proin. Nisl eget ultrices egestas
                                vel viverra facilisi sodales. Orci quisque ac, arcu nunc elementum.
                                Scelerisque amet sed fusce mollis nibh.
                                </p>
                            </div>
                            
                        </div>
                    </Col> */}

                {/* </Row> */}
                <section className="ourTeam">
                    <div>
                        <h2 className="ourTeamText" style={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center" }}>our team</h2>
                    </div>
                </section>
                <Row className="allTeam" style={{display:"flex", justifyContent:"center"}}>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons">
                            <div>
                                <img className="circle" src={team1} alt="" />
                                <h4 className="p1">Mr. SHINOJ JOSEPH</h4>
                                <h6 className="p2">Founder And Chairman</h6>
                                <p className="aboutPerson">
                                Shinoj Joseph is the Founder and Chairman of the Board of Directors of 
                                Ausflug Holidays Pvt Ltd. Mr Shinoj, a renowned business entrepreneur 
                                and philanthropist, known for his extraordinary analytical skills in strategic 
                                planning and systematic developments of business structures, has been the driving 
                                force behind the company ever since its incorporation in 2017. As an MBA holder 
                                from IMT Ghaziabad, Mr. Shinoj has strong background on business and organizational 
                                structures. He has also done Masters in Psychology and English Literature along 
                                with a Post Graduate Diploma in Human Rights.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons2">
                            <div>
                                <img className="circle" src={team2} alt="" />
                                <h4 className="p1">Mr. ZIYAD IBRAHIM</h4>
                                <h6 className="p2">Founder And CEO</h6>
                                <p className="aboutPerson">
                                The Chief Executive Officer, Mr. Ziyad Ibrahim is the Co-Founder and Director of
                                Ausflug Holidays Pvt.Ltd. Mr Ziyad has spent decades in leadership roles in public 
                                and private settings. Soon after the completion of Master’s Degree in Psychology, 
                                As a decisive and dynamic leader and efficient coordinator, who has proven track 
                                record of high Performance and potential, Mr. Ziyad holds insight into the structural 
                                developments of organizational systems. A visionary so passionate about his duties and 
                                responsibilities, Mr. Ziyad is the backbone and heart of the organization.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons">
                            <div>
                                <img className="circle" src={team3} alt="" />
                                <h4 className="p1">Mr. ILYAS KP</h4>
                                <h6 className="p2">Founder And Director</h6>
                                <p className="aboutPerson">
                                Ilyas K P is the co-founder and director of Ausflug holidays Pvt Ltd since its incorporation.
                                Mr. Ilyas, a strategic and innovative thinker and a vibrant motivator, is an excellent team 
                                player who, with proper ethical practice and civic-mindedness, has the ability to think proactively 
                                and to negotiate, communicate and delegate well.
                                Mr. Ilyas holds a Bachelor degree in Civil engineering and diploma in Quantity Surveying & Construction Management.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons2">
                            <div>
                                <img className="circle" src={team4} alt="" />
                                <h4 className="p1">Mr. MUHAMMED NIJAS</h4>
                                <h6 className="p2">Chief Financial Officer</h6>
                                <p className="aboutPerson">
                                Muhammed Nijas is a Director and the Chief Finance Officer of Ausflug Holidays Pvt.Ltd. 
                                Mr. NIJAS has joined the board of directors at Ausflug Holidays Pvt.Ltd in October 2020 and 
                                is currently responsible for managing the financial actions of the company. Mr Nijas plays 
                                vital role in the company as he functions as a monitor at the intersection of strategy, 
                                technology and financial management. His duties
                                include tracking cash flow and Financial planning as well as analysing the company’s financial 
                                strengths and weaknesses and proposing corrective actions. Mr Mr. MUHAMMED NIJAS holds a Bachelor 
                                Degree from the Calicut University in Commerce.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons">
                            <div>
                                <img className="circle" src={team5} alt="" />
                                <h4 className="p1">Ms. JOLLY GEORGE</h4>
                                <h6 className="p2">Director</h6>
                                <p className="aboutPerson">
                                Jolly George is a Director of Ausflug Holidays Pvt Ltd since 2019. Ms Jolly graduated from Adelphi 
                                University with a Bachelor Degree in Mathematics and a Master’s Degree in Elementary Education.
                                After her second graduation from Texas State University with a Master’s Degree in Special Education,
                                As an outgoing person who enjoys meeting people of all walks of life and learning about their 
                                experiences,
                                Jolly helps people embrace differences and encourages them to come together as a community 
                                to accept and help one another.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons2">
                            <div>
                                <img className="circle" src={team6} alt="" />
                                <h4 className="p1">Mr. SANDEEP NEDOOLI</h4>
                                <h6 className="p2">Director</h6>
                                <p className="aboutPerson">
                                Hailing from a medical background, Sandeep Nedooli completed his graduation in nursing from Bhopal 
                                and masters for the same from Jiwaji university Gwalior. Beginning his career from Calicut medical 
                                college. He has organised events in the community by being involved with charities and by
                                volunteering at local companies, clubs and organisations. He has been participating in fundraising events and in campaigns by 
                                conducting medical camps in various communities. Currently settled in UK, apart from being the managing partner in Origo Corp,
                                he is now one among the directors of Ausflug Holidays dealing medical
                                tourism.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons">
                            <div>
                                <img className="circle" src={team7} alt="" />
                                <h4 className="p1">Mr. JITHIN I <br/>KANIYAMATTAM</h4>
                                <h6 className="p2">Marketing Manager</h6>
                                <p className="aboutPerson">
                                Jithin I Kaniyamattam is currently working as a Marketing Manager at Ausflug Holidays Pvt Ltd. 
                                Well-equipped in Travel and Tourism industry with a post- graduation in Master of Tourism 
                                Administration from SBRR Mahajana First Grade College under University of Mysore. As a 
                                highly knowledgeable and result oriented professional, Mr Jithin handles his duties and 
                                responsibilities efficiently and
                                diligently. His passion lies in exploring new terrains and meeting people from all 
                                walks of life, understanding their way of life. As an extraordinary team player and an 
                                efficient coordinator, Mr Jithin is capable of delivering the best for the clients.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                    <Col className="personsCol" xl={4} lg={4} md={4} sm={6} xs={12}>
                        <div className="persons2">
                            <div>
                                <img className="circle" src={team8} alt="" />
                                <h4 className="p1">Ms. SHILPA A</h4>
                                <h6 className="p2">Administrative Manager</h6>
                                <p className="aboutPerson">
                                Currently heading the packaged tours division at Ausflug holidays Pvt Ltd, Shilpa is an experienced 
                                head in tourism industry. As a graduate in Travel and Tourism Management from Providence women’s 
                                college, Calicut under Calicut university, she’s an ardent traveler and quite passionate about 
                                involving herself into travel industry. Apart from being a hodophile, she is interested and 
                                committed in serving the society at large. She also holds masters in Social work from Adaikalamatha
                                 College, Thanjavur under Bharathidasan university, Trichy.
                                </p>
                            
                            </div>
                        </div>
                    </Col>
                </Row>


                {/* <Row style={{ textAlign: "center" }}> */}
                    {/* <Col className="my-3" xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Img fixed={probs.data.teamOne.childImageSharp.fixed} className="team-member-img" />
                        <h4><b>Mr. SHINOJ JOSEPH</b></h4>

                        <h6 style={{ color: "grey", fontWeight: "500" }}>Founder and Chairman</h6>
                        <p className="mt-4" style={{ textAlign: "justify" }}>
                            Shinoj Joseph is the Founder and Chairman of the Board of Directors of Ausflug Holidays Pvt Ltd. Mr Shinoj, a renowned business entrepreneur and philanthropist, known for his extraordinary analytical skills in strategic planning and systematic developments of business structures, has been the driving force behind the company ever since its incorporation in 2017. A visionary leader who tirelessly strives to deliver the best to the society, upholding moral values and organizational integrity, Mr Shinoj is a true mentor and guide to look up to. As an MBA holder from IMT Ghaziabad, Mr. Shinoj has strong background on business and organizational structures. He has also done Masters in Psychology and English Literature along with a Post Graduate Diploma in Human Rights. As a frontrunner and guide, Mr. Shinoj has been part of many public and private entities and projects globally. He had been the coordinator for SADGAMAYA PROJECT, an Integrated Comprehensive Educational Program by Kerala Government and the CEO of BRAINWAVE EDUCATIONAL SERVICES AND TECHNOLOGY. He is the founder of SJ INFOTECH SOLUTION LLC, an US based IT company, headquartered in New York. He is also the chairman of LIFE CARE FOUNDATION, a charitable organization based in India for education rights and human values. He also chairs 'ELITE SOCIAL SERVICE SOCIETY', another NGO working for the upliftment of tribal life.

                                </p>
                    </Col>
                    <Col className="my-3" xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Img fixed={probs.data.teamTwo.childImageSharp.fixed} className="team-member-img" />
                        <h4><b>Mr. ZIYAD IBRAHIM</b></h4>

                        <h6 style={{ color: "grey", fontWeight: "500" }}>Founder and CEO</h6>
                        <p className="mt-4" style={{ textAlign: "justify" }}>
                            The Chief Executive Officer, Mr. Ziyad Ibrahim is the Co-Founder and Director of Ausflug Holidays Pvt.Ltd. Mr Ziyad has spent decades in leadership roles in public and private settings. Soon after the completion of Master’s Degree in Psychology, He started 'EeeQue', a counselling centre for the public, especially for the children who face learning difficulties. Prior to joining the team for Ausflug Holidays Pvt Ltd, Mr Ziyad headed as 'Managing Director' in 'Maghnus Enterprises’ a firm that supplies office equipment’s. Mr. Ziyad also serves on the boards of several private companies. He currently serves as Chairman of the Board of Directors of Origo Corp India, Inc., a publicly-traded Construction material supply company since April 2019. As a decisive and dynamic leader and efficient coordinator, who has proven track record of high Performance and potential, Mr. Ziyad holds insight into the structural developments of organizational systems. A visionary so passionate about his duties and responsibilities, Mr. Ziyad is the backbone and heart of the organization. He is also an Executive Member of LIFE CARE FOUNDATION, a charitable organization for education rights and human values.
                                </p>
                    </Col>
                    <Col className="my-3" xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Img fixed={probs.data.teamThree.childImageSharp.fixed} className="team-member-img" />
                        <h4><b>Mr. ILYAS KP</b></h4>

                        <h6 style={{ color: "grey", fontWeight: "500" }}>Founder and Director</h6>
                        <p className="mt-4" style={{ textAlign: "justify" }}>
                            Ilyas K P is the co-founder and director of Ausflug holidays Pvt Ltd since its incorporation. In his 15 years of career and growth, Mr. Ilyas has been part of many organizations in India and the gulf terrains and he serves on the boards of several private companies. Mr. Ilyas has been a Managing partner of Maghnus Enterprises since 2013, Director & Managing Partner of Floating Hut Resorts at Meenangadi Wayanad since 2018, Founder and Director of Origo Corp Since 2019. Mr. Ilyas, a strategic and innovative thinker and a vibrant motivator, is an excellent team player who, with proper ethical practice and civic-mindedness, has the ability to think proactively and to negotiate, communicate and delegate well. Mr. Ilyas holds a Bachelor degree in Civil engineering and diploma in Quantity Surveying & Construction Management.
                                </p>
                    </Col>
                    <Col className="my-3" xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Img fixed={probs.data.teamFour.childImageSharp.fixed} className="team-member-img" />
                        <h4><b>Mr. MUHAMMED NIJAS</b></h4>

                        <h6 style={{ color: "grey", fontWeight: "500" }}>Chief Financial Officer</h6>
                        <p className="mt-4" style={{ textAlign: "justify" }}>
                            MUHAMMED NIJAS is a Director and the Chief Finance Officer of Ausflug Holidays Pvt.Ltd. Mr. NIJAS has joined the board of directors at Ausflug Holidays Pvt.Ltd in October 2020 and is currently responsible for managing the financial actions of the company. Mr Nijas plays vital role in the company as he functions as a monitor at the intersection of strategy, technology and financial management. His duties include tracking cash flow and Financial planning as well as analysing the company’s financial strengths and weaknesses and proposing corrective actions. Mr Nijas is an expert both at the travel industry and the Financial Departments. Prior to joining Ausflug Holidays Pvt Ltd, Mr Nijas has been part of the executive trams of Ideal Travels and Alhind tours & travels. Mr. MUHAMMED NIJAS holds a Bachelor Degree from the Calicut University in Commerce.

</p>
                    </Col>
                    <Col className="my-3" xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Img fixed={probs.data.teamFive.childImageSharp.fixed} className="team-member-img" />
                        <h4><b>Ms. JOLLY GEORGE</b></h4>

                        <h6 style={{ color: "grey", fontWeight: "500" }}>DIrector</h6>
                        <p className="mt-4" style={{ textAlign: "justify" }}>
                            Jolly George is a Director of Ausflug Holidays Pvt Ltd since 2019. Born and raised in New York, USA, Ms Jolly is currently employed by New York City Department of Education as a High School Special Education Math Teacher. As a passionate Educationalist, Jolly focused her studies in Mathematics and Education. Ms Jolly graduated from Adelphi University with a Bachelor Degree in Mathematics and a Master’s Degree in Elementary Education. After her second graduation from Texas State University with a Master’s Degree in Special Education, she decided to empower the children with disabilities. Her passion lies in educating and supporting individuals to empower and accept themselves as an independent person, breaking away from normalcy and stigmas. As an outgoing person who enjoys meeting people of all walks of life and learning about their experiences, Jolly helps people embrace differences and encourages them to come together as a community to accept and help one another. As a Traveller, she explores new horizons. She is also the founder director of SJ INFOTECH SOLUTION LLC, an US based IT company.
</p>
                    </Col>

                    <Col className="my-3" xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Img fixed={probs.data.sandeep.childImageSharp.fixed} className="team-member-img" />
                        <h4><b>Mr. Sandeep</b></h4>

                        <h6 style={{ color: "grey", fontWeight: "500" }}>DIrector</h6>
                        <p className="mt-4" style={{ textAlign: "justify" }}>
                            Hailing from a medical background, Sandeep Nedooli completed his graduation in nursing from Bhopal and masters for the same from Jiwaji university Gwalior. Beginning his career from Calicut medical college, he continued his profession attaining a remarkable opportunity to work as a clinical instructor in Najran-Saudi Arabia. He also owns a work experience of six years as a Rig medic in grey wolf drilling international- Kuwait, which was under the Kuwait oil company (KOC). Currently settled in United Kingdom, he also is a managing partner in Origo Corp.
                            <br></br>
                            He has organised events in the community by being involved with charities and by volunteering at local companies, clubs and organisations. He has been participating in fundraising events and in campaigns by conducting medical camps in various communities. Currently settled in UK, apart from being the managing partner in Origo Corp, he is now one among the directors of Ausflug Holidays dealing medical tourism.

</p>
                    </Col>

                    <Col className="my-3" xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h4 className="mb-4" style={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center" }}>TRAVEL LEADERSHIP</h4>
                        <Img fixed={probs.data.teamSix.childImageSharp.fixed} className="team-member-img" />
                        <h4><b>Mr. JITHIN I KANIYAMATTAM</b></h4>
                        <h6 style={{ color: "grey", fontWeight: "500" }}>Marketing Manager</h6>
                        <p className="mt-4" style={{ textAlign: "justify" }}>
                            Jithin I Kaniyamattam is currently working as a Marketing Manager at Ausflug Holidays Pvt Ltd. Well-equipped in Travel and Tourism industry with a post-graduation in Master of Tourism Administration from SBRR Mahajana First Grade College under University of Mysore, Mr Jithin started his career in District Tourism Promotion Council, Malappuram as an Assistant in Information Section. Later he joined in Medhop Health Solutions, Calicut, a medical tourism promotion company, as a Medical Value Traveller. As a highly knowledgeable and result oriented professional, Mr Jithin handles his duties and responsibilities efficiently and diligently. His passion lies in exploring new terrains and meeting people from all walks of life, understanding their way of life. His prominent qualities include honesty, confidence and adaptability. As an extraordinary team player and an efficient coordinator, Mr Jithin is capable of delivering the best for the clients.
</p>
                    </Col> */}


                {/* </Row> */}
            </Container>
            </div>
        </Layout>
    )
}
export default about;
export const pageQuery = graphql`
query {
    teamOne: file(relativePath: {eq: "team-1.jpg"}){
        childImageSharp{
            fixed(height:250){
                ...GatsbyImageSharpFixed
            }
        }
    }

    teamTwo: file(relativePath: {eq: "team-2.jpg"}){
        childImageSharp{
            fixed(height:250){
                ...GatsbyImageSharpFixed
            }
        }
    }

    teamThree: file(relativePath: {eq: "team-3.jpg"}){
        childImageSharp{
            fixed(height:250){
                ...GatsbyImageSharpFixed
            }
        }
    }

    teamFour: file(relativePath: {eq: "team-4.jpg"}){
        childImageSharp{
            fixed(height:250){
                ...GatsbyImageSharpFixed
            }
        }
    }
    
    teamFive: file(relativePath: {eq: "team-5.jpg"}){
        childImageSharp{
            fixed(height:250){
                ...GatsbyImageSharpFixed
            }
        }
    }
    teamSix: file(relativePath: {eq: "team-6.jpg"}){
        childImageSharp{
            fixed(width:250){
                ...GatsbyImageSharpFixed
            }
        }
    }

    sandeep: file(relativePath: {eq: "Sandeep-.jpg"}){
        childImageSharp{
            fixed(width:250){
                ...GatsbyImageSharpFixed
            }
        }
    }


}
`

